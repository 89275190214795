import customersEndpoints from "@/store/endpoints/customersEndpoints";
import {PDF} from "@/types/printFormats";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {setTablePagination} from "@/utils/paginator";
import suppliersEndpoints from "@/store/endpoints/suppliersEndpoints";

export default {
    async getCustomers(context) {
        const response = await customersEndpoints.getCustomers()
        if (response.status === 200) {
            context.commit('loadCustomers', response.data)
        }
    },
    async getCustomerPerceptionParameters(context, customerId) {
        const response = await customersEndpoints.getCustomerPerceptionParameters(customerId)
        if (response.status === 200) {
            context.commit('loadCustomerPerceptionParameters', response.data)
        }
    },
    async getCustomerData(context, id) {
        const response = await customersEndpoints.getCustomerData(id)
        return response.data
    },
    async getTransports(context, params) {
        const response = await customersEndpoints.getTransports(params)
        if (response.status === 200) {
            context.commit('loadTransports', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getContactChannels(context) {
        const response = await customersEndpoints.getContactChannels()
        if (response.status === 200) {
            context.commit('loadContactChannels', response.data)
        }
    },
    async getQuotations(context, params) {
        const response = await customersEndpoints.getQuotations(params)
        if (response.status === 200) {
            context.commit('loadQuotations', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getQuotationsSearch(context, params) {
        const response = await customersEndpoints.getQuotationsSearch(params)
        if (response.status === 200) {
            context.commit('loadQuotations', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getQuotationDetails(context, printData) {
        const data = {
            params: {
                quotationId: printData.id,
            },
            endpoint: '/quotation_detail_report',
        }
        const response = await reportsEndpoints.getReport(data)

        if (response.status === 200) {
            if (printData.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async getCustomerCurrentAccountDetails(context, params) {
        const response = await customersEndpoints.getCustomerCurrentAccountDetails(params)
        if (response.status === 200) {
            context.commit('loadCustomerCADetails', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getProofsByCustomer(context, customerId) {
        const response = await customersEndpoints.getProofsByCustomer(customerId)
        if (response.status === 200) context.commit('loadCustomerProofsForImputation', response.data)
    },
    async getSaleProof(context, printData) {
        const data = {
            params: {
                format: PDF.value,
                invoiceId: printData.saleProofId,
            },
            endpoint: '/invoice_report'
        }
        const response = await reportsEndpoints.getReport(data)
        if (response.status === 200) {
            if (printData.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async calculateInvoiceTotals(context, customerProof) {
        const response = await customersEndpoints.calculateInvoiceTotals(customerProof)
        return response.data
    },

    async getCollectionInvoice(context, printData) {
        const data = {
            params: {
                collectionId: printData.collectionId,
            },
            endpoint: '/collection_invoice_detail_report'
        }
        const response = await reportsEndpoints.getReport(data)
        if (response.status === 200) {
            if (printData.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async getCustomerProofsForImputation(context, params) {
        const response = await customersEndpoints.getCustomerProofsForImputation(params)
        if (response.status === 200) context.commit('loadCustomerProofsForImputation', response.data)
    },
    async getCollectionProofsForImputation(context, params) {
        const response = await customersEndpoints.getCollectionProofsForImputation(params)
        if (response.status === 200) context.commit('loadCollectionProofsForImputation', response.data)
    },
    async getCollectionsAndCreditNotes(context, accountId) {
        const response = await customersEndpoints.getCollectionsAndCreditNotes(accountId)
        if (response.status === 200) context.commit('loadCollectionsAndCreditNotes', response.data)
    },
    async getCustomerProofsForCompensation(context, params) {
        const response = await customersEndpoints.getCustomerProofsForCompensation(params)
        if (response.status === 200) context.commit('loadCustomerProofsForCompensation', response.data)
    },
    async getProductSalePrice(context, calculationData) {
        const response = await customersEndpoints.getProductSalePrice(calculationData)
        return response.data
    },
    async calculateInvoiceDetailsTotals(context, customerProof) {
        const response = await customersEndpoints.calculateInvoiceDetailsTotals(customerProof)
        return response.data
    },
    async getImputations(context, params) {
        const response = await customersEndpoints.getImputations(params)
        if (response.status === 200) {
            context.commit('loadCustomerImputations', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getImputationsBySaleProof(context, saleProofId) {
        const params = {
            saleProofId,
            limit: 20,
            page: 1
        }
        const response = await customersEndpoints.getImputations(params)
        if (response.status === 200) {
            context.commit('loadCustomerImputations', response.data.data)
        }
    },
    async getCustomersWithCreditLimit(context) {
        const response = await customersEndpoints.getCustomersWithCreditLimit()
        if (response.status === 200) {
            context.commit('loadCustomersWithCreditLimit', response.data)
        }
    },

    async createCustomer(context, data) {
        const response = await customersEndpoints.createOrEditCustomer(data)
        if (response.status === 200) {
            data.item.customerAttachmentCollection = response.data
            context.commit('addCustomer', data.item)
        }
        return response
    },
    async editCustomer(context, data) {
        const response = await customersEndpoints.createOrEditCustomer(data)
        if (response.status === 200) {
            data.item.customerAttachmentCollection = response.data
            context.commit('uploadCustomer', data)
        }
        return response
    },
    async createCustomerPerceptionParameter(context, parameter) {
        const response = await customersEndpoints.createCustomerPerceptionParameter(parameter)
        if (response.status === 201) {
            context.commit('addCustomerPerceptionParameter', parameter)
        }
    },
    async deleteCustomerPerceptionParameter(context, data) {
        const parameter = context.state.customerPerceptionParameters[data.index]
        const response = await customersEndpoints.deleteCustomerPerceptionParameter({
            parameterId: parameter.id,
            customerId: data.companyId
        })
        if (response.status === 200) {
            context.commit('removeCustomerPerceptionParameter', data.index)
        }
    },
    async deleteContact(context, id) {
        return await customersEndpoints.deleteContact(id)
    },
    async deleteCustomerPricesList(context, id) {
        return await customersEndpoints.deleteCustomerPricesList(id)
    },
    async deleteCurrentAccount(context, account) {
        return await customersEndpoints.deleteCurrentAccount(account)
    },
    async deleteBankAccount(context, id) {
        return await customersEndpoints.deleteBankAccount(id)
    },
    async deleteCustomer(context, index) {
        let item = context.state.customers[index]
        const response = await customersEndpoints.deleteCustomer(item.id)

        if (response.status === 200) {
            context.commit('removeCustomer', index)
        }
    },

    async createTransport(context, data) {
        return await customersEndpoints.createOrEditTransport(data.item)
    },
    async editTransport(context, data) {
        return  await customersEndpoints.createOrEditTransport(data.item)
    },
    async deleteTransport(context, index) {
        let item = context.state.transports[index]
        return await customersEndpoints.deleteTransport(item.id)
    },

    async getSellers(context) {
        const response = await customersEndpoints.getSellers()
        if (response.status === 200) {
            context.commit('loadSellers', response.data)
        }
    },
    async createOrEditSeller(context, seller) {
        const response = await customersEndpoints.createOrEditSeller(seller)
        if (response.status === 201) {
            context.commit('addSeller', seller)
        }
        return response
    },
    async deleteSeller(context, index) {
        let seller = context.state.sellers[index]
        const response = await customersEndpoints.deleteSeller(seller.id)
        if (response.status === 200) {
            context.commit('removeSeller', index)
        }
    },

    async createQuotation(context, quotation) {
        return await customersEndpoints.createQuotation(quotation)
    },
    async deleteQuotation(context, index) {
        const quotation = context.state.quotations[index]
        const response = await customersEndpoints.deleteQuotation(quotation.id)
        if (response.status === 200) context.commit('removeQuotation', index)
    },

    async createCommercialCustomerProof(context, data) {
        return await customersEndpoints.createCommercialCustomerProof(data.customerProof)
    },
    async calculateCollectionTotals(context, collection) {
        const response = await customersEndpoints.calculateCollectionTotals(collection)
        return response.data.data
    },
    async createCollection(context, collection) {
        return await customersEndpoints.createCollection(collection)
    },
    async createCurrentAccountBalance(context, balance) {
        return await customersEndpoints.createCurrentAccountBalance(balance)
    },
    async saveCurrentAccountNotes(context, currentAccount) {
        return await customersEndpoints.saveCurrentAccountNotes(currentAccount)
    },
    async deleteCustomerCurrentAccountDetail(context, detailIndex) {
        const detail = context.state.customerCurrentAccountDetails[detailIndex]
        return await customersEndpoints.deleteCustomerCurrentAccountDetail(detail.id)
    },

    async createCustomerImputations(context, imputations) {
        return await customersEndpoints.createCustomerImputations(imputations)
    },
    async deleteCustomerImputation(context, imputationIndex) {
        const imputation = context.state.imputations[imputationIndex]
        const response = await customersEndpoints.deleteCustomerImputation(imputation.id)
        if (response.status === 200) context.commit('removeCustomerImputation', imputationIndex)
        return response
    },

    async createOrEditCustomerCreditLimit(context, limit) {
        return await customersEndpoints.createOrEditCustomerCreditLimit(limit)
    }
}