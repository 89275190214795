export default {
  loadFormulaChildren(state, children) {
    state.formulaChildren = children
  },
  cleanFormulas(state) {
    state.formulaChildren = []
  },
  loadProductRevisions(state, productRevisions) {
    state.productRevisions = productRevisions
  },

  addProductRevision(state, productRevision) {
    state.productRevisions.push(productRevision)
  },
  updateProductRevision(state, data) {
    Object.assign(state.productRevisions[data.index], data.revision)
  },
  deleteProductRevision(state, index) {
    state.productRevisions.splice(index, 1)
  }
}