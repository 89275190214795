export default {
    loadCustomers(state, customers) {
        state.customers = customers
    },
    loadCustomerPerceptionParameters(state, parameters) {
        state.customerPerceptionParameters = parameters
    },
    loadTransports(state, transports) {
        state.transports = transports
    },
    loadContactChannels(state, channels) {
        state.contactChannels = channels
    },
    loadSellers(state, sellers) {
        state.sellers = sellers
    },
    loadQuotations(state, quotations) {
        state.quotations = quotations
    },
    loadCustomerCADetails(state, details) {
        state.customerCurrentAccountDetails = details
    },
    removeCustomerCADetails(state, index) {
        state.customerCurrentAccountDetails.splice(index, 1)
    },
    loadCustomerProofsForImputation(state, proofs) {
        state.customerProofsForImputation = proofs
    },
    loadCollectionProofsForImputation(state, proofs) {
        proofs.forEach(proof => {
            proof.apply = '0'
            proof.check = false
        })
        state.collectionProofsForImputation = proofs
    },
    loadCustomerProofsForCompensation(state, proofs) {
        proofs.forEach(proof => {
            proof.apply = '0'
        })
        state.customerProofsForCompensation = proofs
    },
    loadCollectionsAndCreditNotes(state, proofs) {
        proofs.forEach(proof => proof.apply = '0')
        state.collectionsAndCreditNotes = proofs
    },
    loadCustomerImputations(state, imputations) {
        state.imputations = imputations
    },
    loadCustomersWithCreditLimit(state, customers) {
        state.customersWithCreditLimit = customers
    },

    addCustomer(state, customer) {
        state.customers.unshift(customer)
    },
    uploadCustomer(state, data) {
        state.customers[data.index] = data.item
    },
    removeCustomer(state, index) {
        state.customers.splice(index, 1)
    },
    addCustomerPerceptionParameter(state, parameter) {
        state.customerPerceptionParameters.push(parameter)
    },
    removeCustomerPerceptionParameter(state, index) {
        state.customerPerceptionParameters.splice(index, 1)
    },
    addSeller(state, seller) {
        state.sellers.unshift(seller)
    },
    removeSeller(state, index) {
        state.sellers.splice(index, 1)
    },
    removeQuotation(state, index) {
        state.quotations.splice(index, 1)
    },
    removeCustomerImputation(state, index) {
        state.imputations.splice(index, 1)
    }
}