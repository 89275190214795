import accountingEndpoints from "@/store/endpoints/accountingEndpoints";
import {setTablePagination} from "@/utils/paginator";

export default {
    async getPeriodClose(context) {
        const response = await accountingEndpoints.getPeriodClose()
        if (response.status === 200) {
            context.commit('loadPeriodClose', response.data)
        }
    },
    async getPaymentMethods(context) {
        const response = await accountingEndpoints.getPaymentMethods()
        if (response.status === 200) {
            context.commit('loadPaymentMethods', response.data)
        }
    },
    async getCollectionMethods(context) {
        const response = await accountingEndpoints.getCollectionMethods()
        if (response.status === 200) {
            context.commit('loadCollectionMethods', response.data)
        }
    },
    async getSupplierPaymentMethods(context) {
        const response = await accountingEndpoints.getSupplierPaymentMethods()
        if (response.status === 200) {
            context.commit('loadSupplierPaymentMethods', response.data)
        }
    },
    async getAccountPlannings(context, params) {
        const response = await accountingEndpoints.getAccountPlannings(params)
        if (response.status === 200) {
            context.commit('loadAccountPlannings', response.data.data)
            setTablePagination(context, response.data)
        }
        return response.data.data
    },
    async getCostCenters(context) {
        const response = await accountingEndpoints.getCostCenters()
        if (response.status === 200) {
            context.commit('loadCostCenters', response.data)
        }
    },
    async getLedgerAccountMovements(context, params) {
        const response = await accountingEndpoints.getLedgerAccountMovements(params)
        if (response.status === 200) {
            context.commit('loadLedgerAccountMovements', response.data.data)
            context.commit('loadLedgerAccountBalances', response.data.balances)
            setTablePagination(context, response.data)
        }
    },
    async getSubCategories(context) {
        const response = await accountingEndpoints.getSubCategories()
        if (response.status === 200) {
            context.commit('loadSubCategories', response.data)
        }
    },
    async getPaymentMethodAccountParameters(context, params) {
        const response = await accountingEndpoints.getPaymentMethodAccountParameters(params)
        if (response.status === 200) {
            context.commit('loadPaymentMethodAccountParameters', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getCommonAccountingEntries(context, params) {
        const response = await accountingEndpoints.getCommonAccountingEntries(params)
        if (response.status === 200) {
            context.commit('loadCommonAccountingEntries', response.data.data)
            setTablePagination(context, response.data)
        }
    },
    async getAccountEntryMovements(context, accountEntryId) {
        const response = await accountingEndpoints.getAccountEntryMovements(accountEntryId)
        return response.data.data
    },
    async getTaxRegistries(context) {
        const response = await accountingEndpoints.getTaxRegistries()
        if (response.status === 200) {
            context.commit('loadTaxRegistries', response.data)
        }
    },
    async getRetentionPerceptionParameters(context, criteria) {
        const response = await accountingEndpoints.getRetentionPerceptionParameters(criteria)
        if (response.status === 200) {
            context.commit('loadRetentionPerceptionParameters', response.data)
        }
    },
    async getTaxTypes(context) {
        const response = await accountingEndpoints.getTaxTypes()
        if (response.status === 200) {
            context.commit('loadTaxTypes', response.data)
        }
    },

    async createOrEditAccountPlanning(context, accountData) {
        return await accountingEndpoints.createOrEditAccountPlanning(accountData.account)
    },
    async deleteAccountPlanning(context, account) {
        const response = await accountingEndpoints.deleteAccountPlanning(account.id)
        if (response.status === 200) {
            context.commit('removeAccountPlannings', response.data.data)
        }
    },
    async createOrEditCostCenter(context, data) {
        const response = await accountingEndpoints.createOrEditCostCenter(data.costCenter)
        if (response.status === 201) {
            if (data.index > -1) {
                context.commit('updateCostCenter', data)
            } else {
                context.commit('addCostCenter', data.costCenter)
            }
        }
    },
    async deleteCostCenter(context, index) {
        const costCenter = context.state.costCenters[index]
        const response = await accountingEndpoints.deleteCostCenter(costCenter.id)
        if (response.status === 200) {
            context.commit('removeCostCenter', index)
        }
    },
    async createOrEditAccountingEntry(context, accountData) {
        return await accountingEndpoints.createOrEditAccountingEntry(accountData.accountEntry)
    },
    async deleteAccountingEntry(context, id) {
        return await accountingEndpoints.deleteAccountingEntry(id)
    },
    async createOrEditPaymentMethodAccountParameter(context, paymentMethodAccountParameter) {
        return await accountingEndpoints.createOrEditPaymentMethodAccountParameter(paymentMethodAccountParameter)
    },
    async separatePaymentMethodAccount(context, accountId) {
        await accountingEndpoints.separatePaymentMethodAccount(accountId)
    },
    async deletePaymentMethodAccountParameter(context, index) {
        const paymentMethodAccountParameter = context.state.paymentMethodAccountParameters[index]
        await accountingEndpoints.deletePaymentMethodAccountParameter(paymentMethodAccountParameter.id)
    },
    async createOrEditPeriodClose(context, data) {
        const response = await accountingEndpoints.createOrEditPeriodClose(data);
        if (response.status === 201) {
            context.commit('addPeriodClose', data)
        }
    },
    async deletePeriodClose(context, period) {
        const response = await accountingEndpoints.deletePeriodClose(period.id)
        if (response.status === 200) {
            context.commit('removePeriodClose', period)
        }

        return response
    },

    async createOrEditTaxRegistry(context, data) {
        const response = await accountingEndpoints.createOrEditTaxRegistry(data);
        if (response.status === 201) {
            data.taxRegistry.attachment = response.data.attachment
            context.commit('addTaxRegistry', data.taxRegistry)
        }
        return response
    },
    async createOrEditRetentionPerceptionParameter(context, data) {
        const response = await accountingEndpoints.createOrEditRetentionPerceptionParameter(data.retentionPerceptionParameter);
        if (response.status === 201) {
            if (data.index === -1) {
                context.commit('addRetentionPerceptionParameter', data.retentionPerceptionParameter)
            }
            return response
        }
    },
    async deleteRetentionPerceptionParameter(context, index) {
        const retentionPerceptionParameter = context.state.retentionPerceptionParameters[index]
        const response = await accountingEndpoints.deleteRetentionPerceptionParameter(retentionPerceptionParameter.id)
        if (response.status === 200) {
            context.commit('removeRetentionPerceptionParameter', index)
        }
        return response
    }
}