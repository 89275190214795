import axios from "axios";
import {host} from "@/store/config";

export default {
  async getFormulasByProduct(id) {
    return await axios.get(host + '/formula_search_by_product', {
      params: {
        id: id,
      }
    })
  },
  async getProductRevisions(criteria) {
    return await axios.get(host + '/product_revision', {
      params: {
        data: JSON.stringify(criteria),
      }
    })
  },
  async createOrEditFormula(vector) {
    return await axios.put(host + '/formula_creator', vector)
  },
  async createOrEditFormulaBatch(formulaData) {
    return await axios.put(host + '/formula_creator', formulaData.vectors, {
      params: {
        isBatch: formulaData.isBatch
      }
    })
  },
  async deleteFormula(params) {
    return await axios.delete(host + '/formula', {
      params: params
    })
  },

  async createOrEditProductRevision(productRevision) {
    return await axios.put(host + '/product_revision', productRevision)
  },
  async deleteProductRevision(revisionId) {
    return await axios.delete(host + '/product_revision', {
      params: {
        revisionId
      }
    })
  },
}