import axios from "axios";
import {host} from "@/store/config";

export default {
    async getCustomers() {
        return await axios.get(host + '/customers')
    },
    async getCustomerPerceptionParameters(id) {
        return await axios.get(host + '/tax/customer_perception_parameter', {
            params: {
                id
            }
        })
    },
    async getCustomerData(id) {
        return await axios.get(host + '/customers', {
            params: {
                id: id,
            }
        })
    },
    async getTransports(params) {
        return await axios.get(host + '/transports', {
            params: params
        })
    },
    async getContactChannels() {
        return await axios.get(host + '/customer_contact_channel')
    },
    async getQuotations(params) {
        return await axios.get(host + '/quotation', {
            params: params
        })
    },
    async getQuotationsSearch(params) {
        return await axios.get(host + '/quotation_search', {
            params: params
        })
    },
    async getCustomerCurrentAccountDetails(params) {
        return await axios.get(host + '/customer_account_detail', {
            params: params
        })
    },
    async getProofsByCustomer(customerId) {
        return await axios.get(host + '/proofs_by_customer', {
            params: {
                id: customerId
            }
        })
    },
    async getCustomerProofsForImputation(params) {
        return await axios.get(host + '/commercial_customer_proofs_for_imputation', {
            params: params
        })
    },
    async getCollectionProofsForImputation(params) {
        return await axios.get(host + '/proofs_for_imputation_on_collection_finder', {
            params: params
        })
    },
    async getCollectionsAndCreditNotes(accountId) {
        return await axios.get(host + '/proofs_to_apply_on_imputations_finder', {
            params: {
                id: accountId
            }
        })
    },
    async getCustomerProofsForCompensation(params) {
        return await axios.get(host + '/customer_proofs_for_compensation', {
            params: params
        })
    },
    async getProductSalePrice(calculationData) {
        return await axios.post(host + '/product_sale_price', calculationData)
    },
    async calculateInvoiceDetailsTotals(customerProof) {
        return await axios.post(host + '/invoice_details_totals', customerProof)
    },
    async calculateInvoiceTotals(customerProof) {
        return await axios.post(host + '/invoice_totals', customerProof)
    },
    async getImputations(params) {
        return await axios.get(host + '/imputed_collection_searcher', {
            params: params
        })
    },
    async getCustomersWithCreditLimit() {
        return await axios.get(host + '/customers', {
            params: {
                hasCreditLimit: true
            }
        })
    },

    async createOrEditCustomer(data) {
        let formData = new FormData()
        data.attachment.forEach(function (file) {
            formData.append('files[]', file, file.fileName)
        })
        formData.append('data', JSON.stringify(data.item))
        return await axios.post(host + '/customers', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    async createCustomerPerceptionParameter(parameter) {
        return await axios.put(host + '/tax/customer_perception_parameter', parameter)
    },
    async deleteCustomerPerceptionParameter(params) {
        return await axios.delete(host + '/tax/customer_perception_parameter', {
            params
        })
    },
    async deleteContact(id) {
        return await axios.delete(host + '/customer_contact', {
            params: {
                id: id,
            }
        })
    },
    async deleteCustomerPricesList(id) {
        return await axios.delete(host + '/customer_price_list', {
            params: {
                id: id,
            }
        })
    },
    async deleteCurrentAccount(account) {
        return await axios.delete(host + '/current_account', {
            params: {
                id: account.id,
                type: account.type
            }
        })
    },
    async deleteBankAccount(id) {
        return await axios.delete(host + '/customer_bank_account', {
            params: {
                id: id,
            }
        })
    },
    async deleteCustomer(id) {
        return await axios.delete(host + '/customers', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditTransport(item) {
        return await axios.put(host + '/transport', item)
    },
    async deleteTransport(id) {
        return await axios.delete(host + '/transport', {
            params: {
                id: id,
            }
        })
    },

    async getSellers() {
        return await axios.get(host + '/seller')
    },
    async createOrEditSeller(seller) {
        return await axios.put(host + '/seller', seller)
    },
    async deleteSeller(id) {
        return await axios.delete(host + '/seller', {
            params: {
                id
            }
        })
    },

    async createQuotation(quotation) {
        return await axios.put(host + '/quotation', quotation)
    },
    async deleteQuotation(id) {
        return await axios.delete(host + '/quotation', {
            params: {
                id: id,
            }
        })
    },

    async createCommercialCustomerProof(proof) {
        return await axios.put(host + '/invoice', proof)
    },
    async calculateCollectionTotals(collection) {
        return await axios.post(host + '/collection_totals', collection)
    },
    async createCollection(collection) {
        return await axios.put(host + '/collection', collection)
    },
    async createCurrentAccountBalance(balance) {
        return await axios.put(host + '/customer_account_balance_creator', balance)
    },
    async saveCurrentAccountNotes(currentAccount) {
        return await axios.put(host + '/customer_current_account_notes', currentAccount)
    },
    async deleteCustomerCurrentAccountDetail(detailId) {
        return await axios.delete(host + '/customer_current_account_detail', {
            params: {
                detailId,
            }
        })
    },
    async createCustomerImputations(imputations) {
        return await axios.put(host + '/customer_proof_imputation', imputations)
    },
    async deleteCustomerImputation(id) {
        return await axios.delete(host + '/imputed_collection', {
            params: {
                id: id,
            }
        })
    },

    async createOrEditCustomerCreditLimit(limit) {
        return await axios.post(host + '/customer-credit-limit-set', limit)
    }
}