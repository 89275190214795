export default {
  customers: [],
  customerPerceptionParameters: [],
  transports: [],
  contactChannels: [],
  pricesLists: [],
  quotations: [],
  sellers: [],
  customerCurrentAccountDetails: [],
  customerProofsForImputation: [],
  customerProofsForCompensation: [],
  collectionProofsForImputation: [],
  collectionsAndCreditNotes: [],
  imputations: [],
  customersWithCreditLimit: [],
}